import './menu.css';
import React from 'react';
import hamburger from '../../../assets/img/menu.svg';
import logo from '../../../assets/img/logo_wht_reg.svg'
import { useMain } from '../../../providers/context';
import lang_pt from '../../../assets/lang/pt.json';
import lang_en from '../../../assets/lang/en.json';
import globe from '../../../assets/img/globe.png';
import united from '../../../assets/img/united.png';
import brazil from '../../../assets/img/brazil.png';


export const Menu = () => {
  const {setLang, lang } = useMain();
  const [menuH, setMenuH] = React.useState<boolean>(false)
  const text = {
      menuName : lang.menu_name, 
      menuService : lang.menu_service,
      menuAbout : lang.menu_about,
      menuContacts : lang.menu_contacts,
      menuLogin : lang.menu_login
  }

  const menuMobile = () =>{
  
    const displayMenuMobile = () =>{
      if(menuH){
        return <>
          <div className="mobile-menu">
            <div>
              <a href="#services" >{text.menuService}</a>
              <a href="#about" >{text.menuAbout}</a>
              <a href="#footer" > {text.menuContacts}</a>
            </div> 
          </div>      
            <div className='mobile-button'>
              <button><a href="/login">{text.menuLogin}</a></button>
            </div>
          
        </>       
      }
    }

    const actionMenu = () =>{
      setMenuH(!menuH)
    }

    return <>
      <div className='menu-mobile'>
        <div className= "mobile-menu-icon">
          <button onClick={()=>actionMenu()}>
            <img className='icon' src={hamburger}/> 
          </button>
        </div>
        {displayMenuMobile()}
      </div>
    </>
  }

  const changeLang = (e: string) => {
    if (e === 'en'){
      setLang(lang_en)
      localStorage.setItem('language','en')
      console.log(lang.menu_service)
    } else {
      setLang(lang_pt)
      localStorage.setItem('language','pt')
    }
    
  }

  // useEffect(()=>{  
  //   if (firstLang === true) {
  //     setTimeout(()=>{
  //       let langStorage = localStorage.getItem("language");        
  //       if (langStorage) {
  //         changeLang(langStorage)
  //       } else {
  //         changeLang('pt')
  //       }
  //     },5000)
     
  //     setFirstLang(false)
  //   }     
  // })

  return(
    <div className="app-menu">

      <nav className='nav-bar'>

        <div className="app-logo">
          <img className='logo' src={logo} alt="Premissanunciada" />
          {/* <p>{text.menuName}</p> */}

          <div className='nav-list'>
            <div>
              <li className='nav-item'><a href="#services" className="App-nav-link">{text.menuService}</a></li>
              <li className='nav-item'><a href="#about" className="App-nav-link">{text.menuAbout}</a></li>
              <li className='nav-item'><a href="#footer" className="App-nav-link">{text.menuContacts}</a></li>
            </div>       
          </div>
        </div>
      
        <div className='login-button'>
        <img className='icon' src={globe}/>
          <select  name="language" id="lang" onChange={(e)=>changeLang(e.currentTarget.value)}>
            <option value="pt">PT<img src={brazil}/></option>
            <option value="en">EN<img src={united}/></option>
          </select>
          <button><a href="/login">{text.menuLogin}</a></button>
        </div>      
      </nav>
      {menuMobile()}

    </div> 
  )
};


export default Menu;