import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useMain } from '../../../providers/context';

export default function ResponsiveDialog( actionModal:any = true) {
  const {setLang, lang } = useMain();
  const text = {
    gdpr_title : lang.gdpr_title,
    gdpr_text : lang.gdpr_text
  }

  const [open, setOpen] = React.useState(false);
  const [stateActionModal, setStateActionModal] = React.useState(actionModal);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const gdprControll = (value:string = 'sim') => {
    localStorage.setItem('consent',value)
    handleClose()
  }

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setStateActionModal(false)
    setOpen(false);
  };
  React.useEffect(()=>{
    if(stateActionModal === true)
    {
      if( !localStorage.getItem('consent') || localStorage.getItem('consent')!=='sim')
        setOpen(stateActionModal)
    }
     
  })
  return (
      <div>
       
        <Dialog
          fullScreen={fullScreen}
          open={open}
          //onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {text.gdpr_title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {text.gdpr_text}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={()=>gdprControll('nao')}>
              Disagree
            </Button>
            <Button onClick={()=>gdprControll('sim')} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }



