import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link } from "react-router-dom";
import './menu.css';
import logo from '../../assets/img/logo4.png'
import { useMain } from '../../providers/context';
import { Button } from '@mui/material';
import { getRoute } from '../helpers/url';

const pages = [    
    {page: 'Home', url: '/dashboard'}, 
    {page: 'Login', url: '/'}, 
];
const settings = [
    {label: '', url: '/dashboard', func: ''},
    {label: 'Home', url: '/dashboard', func: ''},
    {label: 'Perfíl', url: '/profile', func: ''},    
    {label: 'Sair', url: '/', func: 'logout'},
];

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [logoName, setLogoName] = React.useState<string>('')
    const [labelSettings, setLabelSettings] = React.useState<string>('')

    const { user, setUser, setLogged } = useMain();
  
    const handleLogout = () => {
      localStorage.removeItem('user');
      setUser({
        idx: 0,
        id: 0,
        name: '',
        email: '',
        mobile: '',
        image: '',
        token: '',
        cpf: '',
        cnpj: '',
        organization: '',
        permition: false,
        confirmed: false,
        '2fa': false,
        email_verified_at: undefined,
        remember_token: undefined,
        created_at: '',
        updated_at: ''
      });
      setLogged(false);
      setTimeout(() => window.location.href = '/' , 1200)
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const adjustNameAvatar = (name: string): string => {        
        const names: string[] = name.split(' ')
        let newName: string = ''
        names.forEach((el, i) => {            
            if (i === 0)
                newName = el.charAt(0)
            else
                newName += `${el.charAt(0)}`
        })
        return newName
    }

    React.useEffect(() => {
        if (logoName === '')
            setLogoName('Special Situation');
        if (labelSettings === '')
            setLabelSettings('Abrir configurações');
    }, [logoName, labelSettings])

    return (
        <AppBar position="fixed" color="inherit" style={{backgroundColor: '#000'}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>                    
                    <Typography
                        variant="h6"
                        noWrap                        
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                        className="menu-links"
                    >
                        <Link to={pages[0].url}>
                            <img src={logo} alt={logoName} className="logo-dashboard" />
                        </Link>
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none', color: 'white' } }}>                        
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                            className="box-links"
                        >
                        </Menu>
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap                                             
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                        className="menu-links"
                    >
                        <Link to={pages[0].url}>                            
                            <img src={logo} alt={logoName} />
                        </Link>                        
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} className="menu-links"></Box>

                    <Box sx={{ flexGrow: 0 }}>
                        {user.name !== undefined ?
                            <span
                                className='adjustNameAvatar'
                                >{adjustNameAvatar(user.name)}</span>
                        : null}
                        <Tooltip title={labelSettings}>
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar 
                                alt={adjustNameAvatar(user.name)} 
                                src={user.image !== undefined ? user.image : null} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                        {settings.map((setting, i) => (
                            <>
                                { setting.func === 'logout' 
                                    ? <MenuItem key={`setting_${i}`} onClick={handleCloseUserMenu}>
                                        <Typography textAlign="left"> 
                                            <Button onClick={() => handleLogout()}>
                                                {setting.label}
                                            </Button>
                                        </Typography>
                                    </MenuItem>
                                    : setting.label !== '' 
                                        ? getRoute() !== setting.url ? 
                                            <MenuItem key={`setting_${i}`} onClick={handleCloseUserMenu}>
                                                <Typography textAlign="center">
                                                    <Link to={setting.url}><Button>{setting.label}</Button></Link>
                                                </Typography>
                                            </MenuItem>
                                            : null
                                        : <MenuItem key={`setting_${i}`} onClick={handleCloseUserMenu}>
                                            <span>{user.name}</span>
                                        </MenuItem>
                                }
                            </>
                        ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;