import './App.css';
import ResponsiveAppBar from '../../components/menu/menu';
import SideDrawer from '../../components/menu/sideNav';

function App() {
  const dashboardPage = () => {
    return <>
      <ResponsiveAppBar />            
      <div className="App">    
        <section className="App-header" style={{paddingTop: '6.8rem'}}>

          {/* <header className='header-banner'>
          </header>          */}
          
          {/* <Container maxWidth="lg" style={{ display: 'flex' }}>
          </Container> */}
          
          <SideDrawer side="left" />          
        </section>        
      </div>
    </>
  }

  return (
    <div>
      {dashboardPage()}
    </div>
  );
}

export default App;
