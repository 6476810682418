import {    
    Routes,
    Route,
  } from "react-router-dom";
import HomePage from '../../pages/home_page/HomePage';
import App from '../../pages/dashboard/App';
import Login from '../../pages/login/Login';
import Profile from '../../pages/profile/Profile';
import GridImages from '../../pages/grid_images/GridImages';

export const AppRoutes = () => {    
    return <>           
        <Routes>          
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<App />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/grid-images" element={<GridImages />} />
        </Routes >
    </>
}