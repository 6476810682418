import './HomePage.css';
import menu from './component/menu';
import footer from './component/footer';
import gdpr from './component/gdpr';
import app from '../../assets/img/app.png';
import computer from '../../assets/img/computer.png';
import mobile from '../../assets/img/mobile.png'
import { useMain } from '../../providers/context';

const HomePage = () => {
  const { lang } = useMain();
 
  const text = { 
    bannerTitle : lang.banner_title,
    bannerParagrafo : lang.banner_paragrafo,
    bannerBto : lang.banner_bto,
    serviceTitle : lang.service_title,
    serviceDescription : lang.service_description,
    serviceTitle2 : lang.service_title2,
    serviceDescription2 : lang.service_description2,
    serviceTitle3 : lang.service_title3,
    serviceDescription3 : lang.service_description3,
    aboutTitle : lang.about_title,
    aboutDescription1 : lang.about_description1,
    aboutDescription2 : lang.about_description2,
    menuService : lang.menu_service,
  }

  return (
    <div className="App">
      {gdpr()}
      {menu()} 
     <header className="App-header">
        <div className="App-header-content">
          <h1 className="App-title">{text.bannerTitle}</h1>

          <div className="App-sub-title">
            <p>{text.bannerParagrafo}</p>
            <button className="App-header-button"> <a href="#services">{text.bannerBto}</a></button>
          </div>

        </div>
      </header>

     
      <section id="services" className="Services">
        <h2 className='sub-title-dark'>
          {text.menuService}
        </h2>

        <div className='content-service-card'>
          <div className="Service-card">
            <div className="Service-icon">
              <img src={app} alt="running" />
            </div>

            <h3 className="Service-name">
              {text.serviceTitle}
            </h3>

            <p className="Service-description">
              {text.serviceDescription}
            </p>
          </div>  
            
          <div className="Service-card2">
            <div className="Service-icon2">
              <img src={computer} alt="software" />
            </div>
            <h3 className="Service-name2">
              {text.serviceTitle2}
            </h3>
            <p className="Service-description2">
              {text.serviceDescription2}
            </p>
          </div>

          <div className="Service-card3">
            <div className="Service-icon3">
              <img src={mobile}alt="laptop" />
            </div>
            <h3 className="Service-name3">
              {text.serviceTitle3}
            </h3>
            <p className="Service-description3">
              {text.serviceDescription3}
            </p>
          </div>
        </div>

      </section>

      <section id="about" className="about">
        <h2 className="sub-title-light">
          {text.aboutTitle}
        </h2>
        <p>
          {text.aboutDescription1}
          <br />
          <br />
          {text.aboutDescription2}
        </p>
        

      </section>
      
      {footer()}
    </div>
  );
};

export default HomePage;