//import { Avatar } from "@mui/material";
import { useEffect } from "react";
import { getOutOfAccessPage } from "../../components/helpers/url";
import ResponsiveAppBar from "../../components/menu/menu";
import "./GridImages.css";
//import { useMain } from "../../providers/context";

const GridImages = () => {
    //const { user } = useMain();

    useEffect(() => {
        const userStorage = localStorage.getItem("user");        
        if (!userStorage)
            getOutOfAccessPage()
    })

    return <>
        <ResponsiveAppBar />            
        <div className="App">    
            <header className="App-header" style={{paddingTop: '6.8rem'}}>
                <div className="grid-images">
                    <div>
                        {
                            [1,2,3,4,5,6,7,8,9,10,11,12].map((el) => 
                                <div className="little-cubes" key={`key_${el}`}>
                                    <p> item {el}</p>
                                </div>
                            )
                        }
                    </div>
                    <div>
                        <div className="main-card" key={`key_0`}>
                            <p> item 0</p>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    </>
}

export default GridImages