import React, { useEffect, useState } from 'react'
import { LoginResponseData } from '../dto/loginInterface';
import lang_pt from '../assets/lang/pt.json';
import { Lang } from '../dto/interface/lang';

export const MainContext = React.createContext({});

export const MainProvider = (props: any) => {
    const [ logged, setLogged ] = useState<boolean>(false)
    const [ user, setUser ] = useState<LoginResponseData>({
        idx: 0,
        id: 0,
        name: '',
        email: '',
        mobile: '',
        image: '',
        token: '',
        cpf: '',
        cnpj: '',
        organization: '',
        permition: false,
        confirmed: false,
        '2fa': false,
        email_verified_at: undefined,
        remember_token: undefined,
        created_at: '',
        updated_at: ''
    })
    const [ lang, setLang ] = useState<Lang>(lang_pt)     

    useEffect(()=>{
        const userStorage = localStorage.getItem("user");        
        const langStorage = localStorage.getItem("lang");        
        if (userStorage) {
            setUser(JSON.parse(userStorage));
            setLogged(true);
        } else {
            setUser({...user});
            setLogged(false);
        }

        if (langStorage) {
            setLang(JSON.parse(langStorage));
            setLogged(true);
        } else {
            setLang({...lang});
            setLogged(false);
        }
    }, [setUser, setLogged, user, setLang, lang])

    return (
        <MainContext.Provider value={
            { user, setUser, logged, setLogged, lang, setLang }
        } >
            {props.children}
        </MainContext.Provider>
    )
}

export const useMain = () => React.useContext<any>(MainContext); // To do, type it!