import'./footer.css';
import lang from '../../../assets/lang/pt.json';
import linkedin from '../../../assets/img/linkedin.png';
import email from '../../../assets/img/gmail.png';
import logo from '../../../assets/img/logo_full_wht.svg'  
export const Footer = () => {

  const text = {
    footerLogo : lang.footer_logo,
    footerCopyright : lang.footer_copyright
  }

  return (
    <footer id="footer"  className="App-footer">
      <div className="Footer-content">

        <div className='footer-content-left'>
          <div className="Footer-logo">
            <img src={logo} alt="Premissanunciada"  />
            {/* {text.footerLogo}  */}
          </div>
          
        </div>

        <div>
          © {new Date().getFullYear()} {text.footerCopyright}
        </div> 
       
        <div className='footer-content-right'>
          <div className="Footer-linkedin">
            <a href="https://www.linkedin.com/in/marcos-eduardo-81687b45/" target="_blank" rel="noopener noreferrer">
              <img src={linkedin} alt='Linkedin'/>
            </a>
            
            <a href = "mailto: premissanunciadaltd@gmail.com"><img src={email}alt='E-mail'/></a>
          </div>
        </div>
        

      </div>
    </footer>
  );
};

export default Footer;
