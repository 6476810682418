import { HostAPI } from "../../dto/hostUrl"

export const hostApi = () : string => {
    const host: HostAPI = {
        dev: 'http://localhost:8022',
        pro: 'https://io.premissanunciada.com'
    }
    
    return getUrl().includes('localhost:3001') ? host.dev : host.pro
}

export const getRoute = () : string => window.location.pathname
export const getUrl = () : string => window.location.href
export const getOutOfAccessPage = (): any => window.location.href = '/'